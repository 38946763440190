/* https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/ */

/* Remove all visuals from the input range slider */
input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  cursor: pointer;
}
input[type=range]:focus {
  outline: none;
}

/* Apply styles to the slider thumb */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 2px solid #000000;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  height: 20px;
  width: 20px;
}
input[type=range]::-moz-range-thumb {
  border: 2px solid #000000;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}
